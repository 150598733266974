import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ConfigAPI from '@/config/api'
import Session from '@/handler/session'

Vue.use(VueAxios, axios)

const instance = axios.create();

const servicesAPI = {
  obtener() {
    var url = ConfigAPI.baseURL + "customers" + Session.getToken();
    return instance.get(url);
  },
  mostrar(id) {
    var url = ConfigAPI.baseURL + "customers/" + id + Session.getToken();
    return instance.get(url);
  },
  agregar(data, image1) {    
    var headers = {
      'Content-Type': 'multipart/form-data'
    }

    var formData = new FormData();
    data = JSON.parse(data)
    for ( var key in  data) {
      if(key!="image") {
        formData.append(key, data[key]);
      }

      if(key=="image") {
        var i = 0
        image1.forEach((value, key) => {
          formData.append('image_' + i, value);
          i++
        })
        formData.append('image', i);
      }
    }

    var params = formData    

    var url = ConfigAPI.baseURL + "customers" + Session.getToken();
    return instance.post(url,params,{headers});
  },
  editar(data, image1) {
    var headers = {
      'Content-Type': 'multipart/form-data'
    }

    var formData = new FormData();
    data = JSON.parse(data)
    for ( var key in  data) {
      if(key!="image") {
        formData.append(key, data[key]);
      }

      if(key=="image") {
        var i = 0
        image1.forEach((value, key) => {
          formData.append('image_' + i, value);
          i++
        })
        formData.append('image', i);
      }
    }

    var params = formData

    var url = ConfigAPI.baseURL + "customers/"+ data.id + Session.getToken();
    return instance.post(url,params,{headers});
  },
  eliminar(id) {
    var url = ConfigAPI.baseURL + "customers/"+ id + Session.getToken();
    return instance.delete(url);
  },
  obtenerRoles() {
    var url = ConfigAPI.baseURL + "roles" + Session.getToken();
    return instance.get(url);
  },
  crearUsuario(data) {
    var params = {
      name: data.name,
      email: data.email,
      password: data.password,
      roles: data.roles,
    }
    var url = ConfigAPI.baseURL + "customers/createUser/" + data.customer_id + Session.getToken();
    return instance.post(url,params);
  },
  editarUsuarioRoles(data) {
    var params = {
      user_id: data.user_id,
      roles: data.roles,
    }
    var url = ConfigAPI.baseURL + "customers/editUserRoles/" + data.customer_id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarUsuario(data) {
    var params = {
      user_id: data.user_id,
    }

    var url = ConfigAPI.baseURL + "customers/destroyUser/"+ data.customer_id + Session.getToken();
    return instance.post(url,params);
  },
  seleccionarUsuario(data) {
    var params = {
      email: data.email,
    }
    var url = ConfigAPI.baseURL + "customers/selectUser/"+ data.customer_id + Session.getToken();
    return instance.post(url,params);
  },
  vincularUsuario(data){
    var params = {
      user_id: data.user_id,
    }
    var url = ConfigAPI.baseURL + "customers/attachUser/" + data.customer_id + Session.getToken();
    return instance.post(url,params);
  },
  filtrarCliente(data, page) {
    var params = data    
    var url = ConfigAPI.baseURL + "customers/filter/query" + Session.getToken() + "&page=" + page;
    return instance.post(url,params);
  },
  obtenerReportsByCustomers(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "customers/query/reportsByCustomer" + Session.getToken();
    return instance.post(url,params);
  },
  obtenerTypesDocuments() {
    var url = ConfigAPI.baseURL + "sales-types-documents" + Session.getToken();
    return instance.get(url);
  },
  obtenerFiscalConditions() {
    var url = ConfigAPI.baseURL + "sales-fiscal-conditions" + Session.getToken();
    return instance.get(url);
  },
  obtenerMethodsPayment() {
    var url = ConfigAPI.baseURL + "sales-methods-payment" + Session.getToken();
    return instance.get(url);
  },
  agregarSalesCustomers(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "sales-customers" + Session.getToken();
    return instance.post(url,params);
  },
  editarSalesCustomers(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "sales-customers/" + data.id + Session.getToken();
    return instance.post(url,params);
  },
  obtenerPricesLists() {
    var url = ConfigAPI.baseURL + "price-list" + Session.getToken();
    return instance.get(url);
  },
  agregarPricesLists(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "price-list-customers" + Session.getToken();
    return instance.post(url,params);
  },
  editarPricesLists(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "price-list-customers/" + data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarPricesLists(id) {    
    var url = ConfigAPI.baseURL + "price-list-customers/" + id + Session.getToken();
    return instance.delete(url);
  },
  setCommisions(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "customers/marketplace/commisions" + Session.getToken();
    return instance.post(url,params);    
  },
  obtenerContacts(customerID) {
    var url = ConfigAPI.baseURL + "customers/"+customerID+"/contacts" + Session.getToken();
    return instance.get(url);
  },
  mostrarContacts(id, customerID) {
    var url = ConfigAPI.baseURL + "customers/"+customerID+"/contacts/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarContacts(data, customerID) {    
    var params = data
    var url = ConfigAPI.baseURL + "customers/"+customerID+"/contacts" + Session.getToken();
    return instance.post(url,params);
  },
  editarContacts(data, customerID) {
    var params = data    
    var url = ConfigAPI.baseURL + "customers/"+customerID+"/contacts/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarContacts(id, customerID) {
    var url = ConfigAPI.baseURL + "customers/"+customerID+"/contacts/"+ id + Session.getToken();
    return instance.delete(url);
  },
  importarClientes(data, file) {    
    var headers = {
      'Content-Type': 'multipart/form-data'
    }

    var formData = new FormData();
    data = JSON.parse(data)
    for ( var key in  data) {
      if(key!="file") {
        formData.append(key, data[key]);
      }

      if(key=="file") {                
        file.forEach((value, key) => {          
          formData.append('file', value);
        })
      }      
    }

    var params = formData
    var url = ConfigAPI.baseURL + "customers/import/CSV" + Session.getToken();
    
    return instance.post(url,params,{headers});
  },

  filtrarComprobanteById(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "sales/filter/queryById" + Session.getToken();
    return instance.post(url,params);
  },  

  filtrarCurrentsAccounts(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "sales-currents-accounts/filter/query" + Session.getToken();
    return instance.post(url,params);
  },
  filtrarCurrentsAccountsPending(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "sales-currents-accounts/filter/query/pending" + Session.getToken();
    return instance.post(url,params);
  },   
  filtrarCurrentsAccountsInvoice(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "sales-currents-accounts/filter/query/invoice" + Session.getToken();
    return instance.post(url,params);
  },   

  agregarCuentaContable(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "accounting-accounts-customers" + Session.getToken();
    return instance.post(url,params);
  },
  editarCuentaContable(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "accounting-accounts-customers/" + data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarCuentaContable(id) {    
    var url = ConfigAPI.baseURL + "accounting-accounts-customers/" + id + Session.getToken();
    return instance.delete(url);
  },

  mostrarVendedorWithCustomers(id) {
    var url = ConfigAPI.baseURL + "sellers/"+id+"/withCustomers" + Session.getToken();
    return instance.get(url);
  },
       
  obtenerLista() {
    var url = ConfigAPI.baseURL + "price-list" + Session.getToken();
    return instance.get(url);
  },
  obtenerCategoria() {
    var url = ConfigAPI.baseURL + "products-category" + Session.getToken();
    return instance.get(url);
  },
  obtenerLinea() {
    var url = ConfigAPI.baseURL + "products-line" + Session.getToken();
    return instance.get(url);
  },
  filtrarProducto(data, page) {
    var params = data    
    var url = ConfigAPI.baseURL + "products/filter/query" + Session.getToken() + "&page=" + page;
    return instance.post(url,params);
  },  
  updateProducto(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "products-customers/update" + Session.getToken();
    return instance.post(url,params);
  },    
}

export default servicesAPI;
