<template>
  <b-row>
    <b-col lg="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <h4 id="traffic" class="card-title mb-0">Clientes</h4>
            <div class="small text-muted">Vista detallada del cliente seleccionado</div>
          </b-col>
        </b-row>
      </b-card>

      <b-row>
        <b-col lg="4">
          <!-- DATOS BASICOS CLIENTE -->
          <b-card>
            <b-row>
              <b-col>
                <b-avatar :src="customers.image"
                          v-if="customers.image">
                </b-avatar>
                <b-avatar v-else         
                          icon="building"                                    
                          variant="dark">
                </b-avatar>     
                &nbsp;<b>{{customers.name}}</b>     
              </b-col>
            </b-row>

            <b-row>
              <b-col>                    
                
                <div class="ml-5">
                  <div v-if="!customers.email">
                    <b-icon icon="envelope"></b-icon> &nbsp;&nbsp;
                    <b-badge variant="warning" class="badget-custom">No tiene un email asociado</b-badge>                                                    
                  </div>
                  <div v-else>
                    <b-icon icon="envelope"></b-icon> &nbsp;&nbsp;
                    <b-link :href="'mailto:' + customers.email">
                      {{ customers.email }}
                    </b-link> 
                  </div>
                </div>
                
                <div class="ml-5" v-if="customers.phone">
                  <b-icon icon="phone"></b-icon> &nbsp;&nbsp;
                  <b-link :href="'tel:' + customers.phone">
                    {{ customers.phone }}
                  </b-link>               
                </div>                    

              </b-col>
            </b-row>
            
            <b-row v-if="customers.observations || customers.observations_delivery || customers.observations_delivery_address">
              <b-col>
                <hr>
              </b-col>
            </b-row>

            <b-row v-if="customers.registration_website">
              <b-col class="view-customers-observations">                
                <b-badge variant="warning" class="pull-right">Registro desde Sitio Web</b-badge>
              </b-col>
            </b-row>
            <b-row v-if="customers.observations">
              <b-col class="view-customers-observations">
                <b>Observaciones</b>
                <br>
                {{ customers.observations }}
              </b-col>
            </b-row>
            <b-row v-if="customers.observations_delivery">              
              <b-col class="view-customers-observations">
                <br>
                <b>Transporte</b>
                <br>
                {{ customers.observations_delivery }}
              </b-col>
            </b-row>
            <b-row v-if="customers.observations_delivery_address">
              <b-col class="view-customers-observations">
                <br>
                <b>Dirección de Entrega</b>
                <br>
                {{ customers.observations_delivery_address }}
              </b-col>
            </b-row>                                    
          </b-card> 

          <!-- DATOS EXTRAS -->
          <b-card v-if="getFieldInfo">
            <b-row>
              <b-col>

                <b-table-simple hover small caption-top stacked>
                  <caption>Información Adicional:</caption>
                  <colgroup><col><col></colgroup>
                  <colgroup><col><col><col></colgroup>
                  <colgroup><col><col></colgroup>                  
                  <b-tbody>
                    <b-tr>                                            
                      <b-td stacked-heading="DNI">
                        {{this.getFieldInfo['dni']}}
                      </b-td>
                    </b-tr>                                            
                    <b-tr>                                            
                      <b-td stacked-heading="Instagram">
                        {{this.getFieldInfo['instagram']}}
                      </b-td>                      
                    </b-tr>
                    <b-tr>                                            
                      <b-td stacked-heading="Newslleters">
                        <div v-if="this.getFieldInfo['newsletter']">
                          SI
                        </div>
                        <div v-else>
                          NO
                        </div>                        
                      </b-td>                      
                    </b-tr>                    
                  </b-tbody>
                </b-table-simple>
              </b-col>
            </b-row>
          </b-card>     

          <Contacts :customers="customers" :customersID="customersID"></Contacts>
        </b-col> 

        <b-col lg="8">          
          <b-tabs active-nav-item-class="font-weight-bold text-uppercase text-dark"                               
                  v-if="showTab">

            <b-tab title="Datos Fiscales" v-if="this.configTabs.fiscal.show" :active="tabs.fiscal" @click="setActiveTab('fiscal')">
                <tab-fiscal v-if="isCustomersSales" :customers="customers" :customersID="customersID"></tab-fiscal>
            </b-tab>  

            <b-tab title="Informes" v-if="this.configTabs.reports.show" :active="tabs.reports" @click="setActiveTab('reports')">
              <tab-report :customers="customers" :customersID="customersID" :reports="configTabs.reports"></tab-report>
            </b-tab>

            <b-tab title="Lista de Precio" v-if="this.configTabs.list.show" :active="tabs.price_list" @click="setActiveTab('price_list')">
                <tab-list v-if="isCustomersPriceList" :customers="customers" :customersID="customersID"></tab-list>
            </b-tab>

            <b-tab title="Productos" v-if="this.configTabs.products.show" :active="tabs.products" @click="setActiveTab('products')">
              <tab-products :customers="customers" :customersID="customersID"></tab-products>
            </b-tab>

            <b-tab title="Cursos" v-if="this.configTabs.courses.show" :active="tabs.courses" @click="setActiveTab('courses')">
              <tab-courses :customers="customers" :customersID="customersID"></tab-courses>
            </b-tab>

            <b-tab title="Servicios" v-if="this.configTabs.services.show" :active="tabs.services" @click="setActiveTab('services')">
              <tab-services :customers="customers" :customersID="customersID"></tab-services>
            </b-tab>

            <b-tab title="Cuenta Corriente" v-if="this.configTabs.currentAccount.show" :active="tabs.currentAccount" @click="setActiveTab('currentAccount')">
              <tab-currentAccount :customers="customers" :customersID="customersID"></tab-currentAccount>
            </b-tab>

            <b-tab title="Cuenta Contable" v-if="this.configTabs.accountingAccount.show" :active="tabs.accountingAccount" @click="setActiveTab('accountingAccount')">
              <tab-accountingAccount :customers="customers" :customersID="customersID"></tab-accountingAccount>
            </b-tab>
          </b-tabs>
          <b-alert v-else show variant="warning">
            No se encotró información de módulos relacionados.
          </b-alert>
        </b-col>

      </b-row>

      <b-card class="mt-2" header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>
        </b-row>
      </b-card>  

    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper'
  import Widget from '@/components/inc/widget/widget'
  import moment from 'moment'
  import Param from '@/config/parameters'  
  import Storage from '@/handler/storageLocal'  
  import TabReport from './tabs/reports'
  import TabFiscal from './tabs/fiscal'
  import TabList from './tabs/list'
  import TabCourses from './tabs/courses'
  import TabServices from './tabs/services'
  import TabCurrentAccount from './tabs/currentAccount'
  import TabAccountingAccount from './tabs/accountingAccount'
  import TabProducts from './tabs/products'
  import Contacts from './contacts/crud'
  
  export default {
    components: {
      Widget,
      TabReport,
      TabFiscal,
      TabList,
      TabCourses,
      TabServices,
      TabCurrentAccount,
      TabAccountingAccount,
      TabProducts,
      Contacts,
    },
    data: () => {
      return {
        access: {
          module_id: Modules.CLIENTES,
          profile_id: Profiles.PERSONAL,
          view_reference: 'view',
          elements: {}
        },
        parameters: {          
          haveColors: Helper.hasParametersAccess(Param.P8),
          haveWaist: Helper.hasParametersAccess(Param.P9),
          haveColorsWaist: Helper.hasParametersAccess(Param.P17),          
        },           
        configTabs: {
          reports: {
            show: false,
            access: {
              visits: false,
              payments: false,
              news: false,
              services: false,
              audit: false,
              marketing: false,
            },            
          },
          fiscal: {
            show: false,
          },
          list: {
            show: false,
          },
          courses: {
            show: false,
          },
          services: {
            show: false,
          },
          currentAccount: {
            show: false,
          },
          accountingAccount: {
            show: false,
          },
          products: {
            show: false,
          },          
        },
        primaryColor: '',
        customersID: 0,
        customers: {},
        modulesActive: [],
        tabs: {
          fiscal: true,
          price_list: false,
          reports: false,
          courses: false,
          currentAccount: false,
          accountingAccount: false,
          products: false,
        }
      }
    },
    created () {
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },
    mounted() {
      this.customersID = this.$route.params.customersID  
      this.modulesActive = Session.getSession().auth.user.permissions.modules

      this.setup()
      this.load()
      this.configAccess()
      this.validation()

      this.restoreStatusTabsStorage()
    }, 
    computed: {
      showTab () {
        var status = false
        if(this.configTabs.reports.show ||
           this.configTabs.fiscal.show ||
           this.configTabs.list.show ||
           this.configTabs.courses.show ||
           this.configTabs.services.show ||
           this.configTabs.currentAccount.show ||
           this.configTabs.accountingAccount.show ||
           this.configTabs.products.show) {
          status = true
        }
        return status
      },    
      isCustomersSales() {  
        if(this.customers.data_fiscal) {
          return true
        } else {
          if(this.customers.data_fiscal==undefined) {
            return true
          } else {
            return false
          }
        }
      },
      isCustomersPriceList() {        
        if(this.customers.price_list) {
          return true
        } else {
          if(this.customers.sale==undefined) {
            return true
          } else {
            return false
          }
        }
      }, 
      getFieldInfo() {        
        if(this.customers.field_info) {
          var field_info = JSON.parse(this.customers.field_info)
          return field_info           
        } else {
          return null
        }
      },   
      isSeller() {
        var seller = Helper.getSeller()
        if(seller) {
          return true
        } else {
          return false
        }
      },  

      hasOnlyProduct() {      
        return !this.parameters.haveColorsWaist && !this.parameters.haveColors && !this.parameters.haveWaist
      },        
    },
    methods: {   
      validation() {        
        var seller = Helper.getSeller()   

        if(seller) {                    
          var result = serviceAPI.mostrarVendedorWithCustomers(seller.id)

          result.then((response) => {        
            var objSeller = response.data 
            
            if(objSeller.customers) {
              var status = false
              objSeller.customers.forEach(element => {              
                if(this.customersID == element.id) {
                  status = true
                }   
              });
              if(!status) {
                this.$router.push({ name: 'Error403' })    
              }
            }                      
          })          
        }
      },            
      load() {
        let loader = this.$loading.show();
        var result = serviceAPI.mostrar(this.customersID)

        result.then((response) => {
          var data = response.data
          this.customers = data         
          loader.hide()
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error))
        });
      },      
      setup () {        
        if(Session.getSession().settings) {    
          this.primaryColor = Session.getSession().settings.color_primary
                    
          this.createCSS()          
        }
      },         
      createCSS() {
        var hoja = document.createElement('style')
        hoja.innerHTML = ""      
        hoja.innerHTML = hoja.innerHTML  + ".list-group-item-action:hover, .list-group-item-action:focus { background-color: " + this.primaryColor + "; }"
        hoja.innerHTML = hoja.innerHTML  + ".view-customer-access-reports-widget-card-text-primary { color: " + this.primaryColor + "; }"
        
        document.body.appendChild(hoja);
      },
      configAccess() {
        this.modulesActive.forEach(element => {

          if(element.id == Modules.INFORME_VISITAS ||
             element.id == Modules.INFORME_COBRANZAS ||
             element.id == Modules.INFORME_NOVEDADES ||
             element.id == Modules.INFORME_SERVICIOS ||
             element.id == Modules.INFORME_AUDITORIA ||
             element.id == Modules.INFORME_MARKETING) {
               this.configTabs.reports.show = true

               if(element.id == Modules.INFORME_VISITAS) {
                 this.configTabs.reports.access.visits = true
               }
               if(element.id == Modules.INFORME_COBRANZAS) {
                 this.configTabs.reports.access.payments = true
               }
               if(element.id == Modules.INFORME_NOVEDADES) {
                 this.configTabs.reports.access.news = true
               }                              
               if(element.id == Modules.INFORME_SERVICIOS) {
                 this.configTabs.reports.access.services = true
               }                                
               if(element.id == Modules.INFORME_AUDITORIA) {
                 this.configTabs.reports.access.audit = true
               }                                 
               if(element.id == Modules.INFORME_MARKETING) {
                 this.configTabs.reports.access.marketing = true
               }                                       
          }

          if(element.id == Modules.VENTAS || element.id == Modules.PEDIDOS) {
            this.configTabs.fiscal.show = true
          }

          if(element.id == Modules.VENTAS) {
            this.configTabs.currentAccount.show = true
          }

          if(element.id == Modules.PRECIOS) {
            this.configTabs.list.show = true
          }

          if(element.id == Modules.CURSOS) {
            this.configTabs.courses.show = true
          }

          if(element.id == Modules.SERVICIOS) {
            this.configTabs.services.show = true
          }

          if(element.id == Modules.CONTABILIDAD) {
            this.configTabs.accountingAccount.show = true
          }

          if((element.id == Modules.PRODUCTOS || element.id == Modules.PRECIOS) && this.hasOnlyProduct) {
            this.configTabs.products.show = true
          }          
        })
      },

      //TABS
      disableAllTabs() {
        this.tabs.fiscal = false
        this.tabs.price_list = false
        this.tabs.reports = false
        this.tabs.courses = false
        this.tabs.services = false
        this.tabs.currentAccount = false
        this.tabs.accountingAccount = false
        this.tabs.products = false
      },
      setActiveTab(value) {
        this.disableAllTabs()

        switch (value) {
          case 'fiscal':
            this.tabs.fiscal = true
            break;
        
          case 'reports':
            this.tabs.reports = true
            break;
            
          case 'price_list':
            this.tabs.price_list = true
            break;        

          case 'courses':
            this.tabs.courses = true
            break;        

          case 'services':
            this.tabs.services = true
            break;        

          case 'currentAccount':
            this.tabs.currentAccount = true
            break;        

          case 'accountingAccount':
            this.tabs.accountingAccount = true
            break;

          case 'products':
            this.tabs.products = true
            break;

          default:
            this.tabs.fiscal = true
            break;
        }

        this.saveStatusTabsStorage()
      },

      // FILTROS SAVE
      saveStatusTabsStorage() {
        Storage.setValue('tabs_view_cutomers', JSON.stringify(this.tabs))
      },
      restoreStatusTabsStorage() {
        if(Storage.getValue('tabs_view_cutomers')) {
          this.tabs = JSON.parse(Storage.getValue('tabs_view_cutomers'))
        }         
      },      
    }    
  }
</script>

<style>
  .view-customer-access-reports .list-group-item {
    padding: 6px;
  }
  .view-customer-access-reports h5 {
    margin-bottom: 0px;
  }
  .view-customer-access-reports svg {
    margin-bottom: 0px;
  }  
  .view-customer-access-reports-widget-card .card-header {
    padding: 5px;
  }
  .view-customer-access-reports-widget-card .card-body {
    padding: 5px;
  }  
  .view-customer-access-reports-widget-card-text-info {
    font-size: 12px;
  }
  .view-customer-access-reports-widget-card-text-primary {
    font-size: 25px;  
    font-weight: bold;
  }  
  .view-customer-access-reports-widget-card-empty {
    font-size: 15px;
    margin-top: 10px;
  }

  .view-customer-access-reports-widget-link-reports {
    cursor: pointer;
  }
  .view-customer-access-reports-widget-badget-custom {
    font-size: 100%;
  }  
  .view-customer-access-reports-widget-date {
    font-size: 13px;
  }
  .view-customer-access-reports-widget-info-extra {
    text-align: left;
    font-size: 15px;
  }
  .view-customer-access-reports-widget-scroll {
    height: 300px;
    overflow: overlay;
  }
</style>
<style scoped>
  hr {
    margin-top: 5px;
    margin-bottom: 5px;    
  }
  .view-customers-observations {
    overflow: auto;
    max-height: 65px; 
    font-size: 12px;   
  }  
</style>
