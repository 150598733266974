<template>
  <div>
    <b-row>
      <b-col sm="4">
        <v-select :options="arr.select.active" v-model="filters.active" placeholder="Estado" :multiple="false" :select-on-tab="true"></v-select>
        <b-form-input type="text" 
                      placeholder="Ingrese un código o nombre de producto" 
                      v-model="filters.name"
                      size="sm">
        </b-form-input>                
      </b-col>  
      <b-col sm="4">
        <v-select :options="arr.select.categorys" v-model="filters.categorys" placeholder="Categorías" :multiple="false" :select-on-tab="true" @input="filterLoadSubCategorys" ></v-select>
        <v-select :options="arr.select.subcategorys" v-model="filters.subcategorys" placeholder="Subcategorías" :multiple="true" :select-on-tab="true"></v-select>
      </b-col>  
      
      <b-col sm="3" v-if="hasLines">
        <v-select :options="arr.select.lines" v-model="filters.lines" placeholder="Lineas" :multiple="false" :select-on-tab="true" @input="filterLoadSubLines"></v-select>
        <v-select :options="arr.select.sublines" v-model="filters.sublines" placeholder="Sublineas" :multiple="true" :select-on-tab="true"></v-select>
      </b-col>

      <b-col sm="1">
        <b-row>
          <b-col lg="12">
            <b-button variant="outline-dark" @click="load()" size="sm" class="pull-right" title="Filtrar">
              <b-icon icon="filter" style="font-size:15px;"></b-icon>                            
            </b-button>
          </b-col>         
          <b-col lg="12">
            <b-link @click="openExport()" class="mt-3 pull-right">
              Exportar
            </b-link>
          </b-col>           
        </b-row>
      </b-col>          
    </b-row>

    <b-row>    
      <b-col>      
        <b-table class="mb-0 table-products-custom"
                ref="table"
                responsive
                head-variant="dark"
                :hover="true"
                :small="true"
                :fixed="true"
                :items="table.items"
                :fields="table.fields"
                :filter="table.filter"
                :current-page="table.currentPage"
                :per-page="table.perPage"
                selectable
                select-mode="single"              
                :busy="table.isBusy"                     
                v-if="table.items.length || table.isBusy">                                               
            
            <template v-slot:table-colgroup="scope">
              <col
                v-for="field in scope.fields"                    
                :key="field.key"
                :style="{ width: field.width }"
              >
            </template> 

            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Cargando...</strong>
              </div>
            </template>
                                          
            <template v-slot:cell(id)="data">
              <b>{{data.item.id}}</b>
            </template>

            <template v-slot:cell(name)="data">
              <div class="crud-products-customers-title">
                <span v-if="data.item.code" class="crud-products-customers-code">
                  {{data.item.code}}<br>
                </span>
                {{data.item.name}}
              </div>
            </template>
            
            <template v-slot:cell(prices)="data">      
              <div class="crud-products-customers-other">
                <div v-if="data.item.prices.length">
                  <b :title="data.item.prices[0].list.name" v-b-tooltip.hover>
                    <span v-if="data.item.prices[0].list.reference">
                      {{data.item.prices[0].list.reference.substring(0,5)}}
                    </span>
                    <span v-else>
                      {{data.item.prices[0].list.name.substring(0,5)}}
                    </span>                              
                  </b> : <br>
                  {{Intl.NumberFormat('es-AR',{style:'currency',currency:data.item.prices[0].list.currency.code}).format(data.item.prices[0].amount_total)}} 
                  <span v-if="data.item.prices[0].unit_measurement_result">{{data.item.prices[0].unit_measurement_result.reference}}</span>
                  <span v-if="parseFloat(data.item.prices[0].desc) > 0" class="ml-1">
                    <i>({{Intl.NumberFormat('es-AR',{style:'percent', maximumFractionDigits:2}).format(parseFloat(data.item.prices[0].desc)/100)}})</i>
                  </span>
                </div>       
              </div>
            </template>

            <template v-slot:cell(f_action)="data">              
              <b-input-group size="sm" class="crud-products-customers-other">
                <b-input-group-prepend is-text>-</b-input-group-prepend>                
                <b-form-input aria-label="Descuento" title="Descuento" v-model="crud.prodTMP[data.index]['discount']" type="number" step="0.01" min="0"></b-form-input>
                <b-input-group-prepend is-text>+</b-input-group-prepend>                    
                <b-form-input aria-label="Adiccional" title="Adiccional" v-model="crud.prodTMP[data.index]['additional']" type="number" step="0.01" min="0"></b-form-input>                
                <b-input-group-append>
                  <b-button variant="success" title="Confirmar" @click="save(crud.prodTMP[data.index])">
                    <b-icon icon="check2" />
                  </b-button>
                </b-input-group-append>                
              </b-input-group>
            </template>

            <template v-slot:cell(prices_result)="data">      
              <div class="crud-products-customers-other">
                <div v-if="data.item.prices.length">
                  <span v-if="data.item.prices[0]._customer_price">
                    {{Intl.NumberFormat('es-AR',{style:'currency',currency:data.item.prices[0].list.currency.code}).format(data.item.prices[0]._customer_price)}} 
                  </span>
                  <span v-else>
                    {{Intl.NumberFormat('es-AR',{style:'currency',currency:data.item.prices[0].list.currency.code}).format(data.item.prices[0].amount_total)}} 
                  </span>
                  
                  <span v-if="data.item.prices[0].unit_measurement_result">{{data.item.prices[0].unit_measurement_result.reference}}</span>                                          
                </div>                  
              </div>
            </template>            
        </b-table>
        <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>      
      </b-col>              
    </b-row>

    <b-row>
      <b-col>
        <nav v-if="table.tablePaginate">
          <b-pagination class="pull-right mb-0"
                        size="sm"
                        pills
                        :total-rows="table.tablePaginate.total"
                        :per-page="table.tablePaginate.per_page"
                        v-model="table.currentPage"  
                        @input="load()"/>
        </nav>
      </b-col>          
    </b-row>

    <!--
    ###########################
    ######### PRINTER #########
    ###########################
    -->
    <Printer v-if="crud.print.filters" 
            :filters="crud.print.filters"
            :reference="crud.print.reference"
            :key="crud.print.key"/>


    <!-- CRUD EXPORTACIONES -->      
    <b-modal  v-model="modal.formExport.active"
              header-bg-variant="dark"
              header-text-variant="white"
              size="lg">
      <div slot="modal-header">
        {{this.modal.formExport.title}}
      </div>
      
      <b-row>
        <b-col md="12" v-if="modal.formExport.price_list_id">
          <b-overlay :show="modal.formExport.overlay" rounded="sm">
            <b-table-simple hover small caption-top responsive>    
              <b-thead head-variant="dark">
                <b-tr>
                  <b-th>Tipo de Exportación</b-th>
                  <b-th class="text-right">Acciones</b-th>        
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-td>Lista de precios completa. <i>(Aplica Filtros)</i></b-td>
                  <b-td class="text-right">
                    <b-button-group size="sm">
                      <b-button @click="downloadSource('filter-full-list-price')" class="mr-1">
                        <b-icon icon="download"></b-icon>
                        CSV  
                      </b-button>                  
                      <b-button @click="printPDF('filter-full-list-price')" class="mr-1">
                        <b-icon icon="printer"></b-icon>
                        PDF
                      </b-button>                                                          
                    </b-button-group>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td>Productos modificados en los últimos 7 días. <i>(Aplica Filtros)</i></b-td>
                  <b-td class="text-right">
                    <b-button-group size="sm">
                      <b-button @click="downloadSource('filter-list-price-last-update')" class="mr-1">
                        <b-icon icon="download"></b-icon>
                        CSV  
                      </b-button>                  
                      <b-button @click="printPDF('filter-list-price-last-update')" class="mr-1">
                        <b-icon icon="printer"></b-icon>
                        PDF
                      </b-button>                                                          
                    </b-button-group>
                  </b-td>
                </b-tr>                  
              </b-tbody>
            </b-table-simple>
          </b-overlay>
        </b-col>
      </b-row>

      <div slot="modal-footer">
        <b-button variant="outline-secondary" class="mr-1" @click="modal.formExport.active=false">Cancelar</b-button>
      </div>
    </b-modal>      
  </div>    
</template>
<script>
    import serviceAPI from './../services'
    import Error from '@/handler/error'
    import ErrorToken from '@/handler/errorToken'  
    import Session from '@/handler/session'
    import Profiles from '@/config/profiles'
    import Modules from '@/config/modules'
    import Helper from '@/handler/helper'    
    import FindObject from '@/components/inc/find/findObject'  
    import Param from '@/config/parameters'  
    import Funciones from '@/handler/funciones'
    import Printer from '@/components/inc/printer/printer'

    export default {
      components: {
        FindObject,       
        Printer,    
      },        
      props: [
          'customers',
          'customersID',
      ],
      data() {
        return {      
          customersTMP: null,    
          parameters: {
            haveLines: Helper.hasParametersAccess(Param.P7),
            enableDiscOrAdd: Helper.hasParametersAccess(Param.P53),
          },    
          table : {
            items: [],
            fields: [],
            filter: null,
            mostrarFiltros: true,
            currentPage: 1,
            perPage: 0,
            isBusy: false,
            tablePaginate: null,
          },  
          crud: {
            prodTMP: [],
            print: {
              key: 0,
              filters: null,
              reference: 'products-price-list',
            }  
          },
          modal: {
            formExport: {
              active: false,
              title: '',
              price_list_id: 0,
              overlay: false,
            },  
          },                 
          arr: {
            select:{                    
              lines: [],
              sublines:[],
              categorys: [],
              subcategorys:[],
              active:[
                {code:true, label: 'Activo'},
                {code:false, label: 'Inactivo'},              
              ],
            },
            priceList: [],
          },  
          filters: {
            lines: null,
            sublines: null,
            categorys: null,
            subcategorys: null,
            name: '',
            active: {code:true, label: 'Activo'},
            customerIDAgreement: 0,
          },     
          modulesActive: [], 
          modules: {
            showPrices: false
          },                                    
          componentKey: 0,
        }
      },
      watch: {        
        customers: function (newQuestion, oldQuestion) {
          this.filters.customerIDAgreement = newQuestion.id
        }
      },      
      computed: {
        hasLines() {
          return this.parameters.haveLines
        },                
        isSeller() {
          var seller = Helper.getSeller()
          if(seller) {
            return true
          } else {
            return false
          }
        },  

        getPublicPriceList() {
          var list = null
          this.arr.select.priceList.forEach(element => {
            if(element.active && element.public && element.currency.code == 'ARS') {
              list = element
            }
          });
          return list
        }
      },        
      mounted() {
        this.modulesActive = Session.getSession().auth.user.permissions.modules
        this.configAccess()

        this.loadFieldTable()

        this.filterLoad()        
      }, 
      methods: {
        configAccess() {
          this.modulesActive.forEach(element => {          
            if(element.id == Modules.PRECIOS) {                          
              this.modules.showPrices = true                             
            }
          })
        },                   

        loadFieldTable () {
          // TABLE PRODUCTOS
          this.table.fields.push({key: 'id', label: 'ID', sortable: true, class:"align-middle text-center", width:"30px"})
          this.table.fields.push({key: 'name', label: 'Nombre', class:"align-middle", width:"210px"})              
          if(this.modules.showPrices) {
            this.table.fields.push({key: 'prices', label: 'Precio Lista', class:"align-middle", width:"110px"})      
          }                               
          if(this.parameters.enableDiscOrAdd) {
            this.table.fields.push({key: 'f_action', label:'Desc/Adic (%)', class:"align-middle text-center", width:"170px"})
          }          
          if(this.modules.showPrices) {
            this.table.fields.push({key: 'prices_result', label: 'Precio Venta', class:"align-middle text-right", width:"100px"})      
          }                                         
        },          

        filterLoad() {
          this.filterLoadLines()
          this.filterLoadCategorys()
          this.filterLoadPriceList()
        },
        filterLoadLines() {
          var result = serviceAPI.obtenerLinea()        
          result.then((response) => {
            var data = response.data    
            
            data.forEach(element => {      
              this.arr.select.lines.push({code: element.id, label: element.name})                                                            
            });                                
          })   
        },
        filterLoadSubLines(val) {        
          this.arr.select.sublines=[]
          if(val) {
            var result = serviceAPI.obtenerLinea()        
            result.then((response) => {
              var data = response.data    
              
              data.forEach(element => {         
                if(element.id == val.code) {
                  element.sublines.forEach(element1 => {
                    this.arr.select.sublines.push({code: element1.id, label: element1.name})                        
                  }); 
                }
              });                                
            })   
          }
        },
        filterLoadCategorys() {
          var result = serviceAPI.obtenerCategoria()        
          result.then((response) => {
            var data = response.data    

            data.forEach(element => {              
              this.arr.select.categorys.push({code: element.id, label: element.name})
            });                                
          })   
        },   
        filterLoadSubCategorys(val) {
          this.arr.select.subcategorys = []
          if(val) {          
            var result = serviceAPI.obtenerCategoria()        
            result.then((response) => {
              var data = response.data    

              data.forEach(element => {          
                if(element.id == val.code) {
                  element.subcategorys.forEach(element1 => {                
                    this.arr.select.subcategorys.push({code: element1.id, label: element1.name})
                  }); 
                }                                  
              });                                
            })   
          }
        },
        filterLoadPriceList() {
          var result = serviceAPI.obtenerLista()        
          result.then((response) => {
            var data = response.data    
            this.arr.select.priceList = data                
          })   
        },

        filterProducts(forceBusy=true, msjDefault = '') {                      
          this.table.isBusy = forceBusy       
          
          this.filters.listFullToCSV = false
          this.filters.price_list_id = 0
          this.filters.last_update_days = 0
          this.filters.customerIDAgreement = this.customers.id     

          var result = serviceAPI.filtrarProducto(this.filters, this.table.currentPage)        
          result.then((response) => {          
            var data = response.data
            this.table.tablePaginate = data
            this.table.items = data.data             

            this.table.items.forEach((element, index) => {
              if(!element.active) {
                element._rowVariant = 'danger'                          
              }                                

              if(!this.crud.prodTMP[index])
                this.crud.prodTMP[index] = []
              
              var item  = null
              if(this.customersTMP && this.customersTMP.products_customers) {

                this.customersTMP.products_customers.find(prod => {
                  if(prod.product_id == element.id) {
                    item = prod
                  }
                })
              }

              this.crud.prodTMP[index] = {
                id: element.id,            
                product_id: element.id,
                customer_id: this.customersTMP.id,    
                discount: item ? item.discount : 0,
                additional: item ? item.additional : 0,
              }

              if(element.prices) {
                if(this.customersTMP.price_list)
                  element.prices = element.prices.filter(p => p.price_list_id == this.customersTMP.price_list.price_list_id)
                else
                  element.prices = element.prices.filter(p => p.price_list_id == this.getPublicPriceList.id)                  
              }              
            });
            this.table.isBusy = false

            if(msjDefault)
              this.$awn.info(msjDefault)

          })           
          .catch(error => {              
            this.table.isBusy = false
            this.$awn.alert(ErrorToken.valid(error));
          })  
        },        

        load() {          
          if(this.customers) {
            var result = serviceAPI.mostrar(this.customers.id)
            result.then((response) => {      
              this.customersTMP = response.data
              
              this.filterProducts()                          
            })
          }                                   
        },                    
        save(item) {
          this.$bvModal.msgBoxConfirm('¿Actualizar acuerdo comercial?', {
            title: 'Acuerdo comercial',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'dark',
            okTitle: 'ACTUALIZAR',
            cancelTitle: 'NO',
            footerClass: 'p-2',
            headerBgVariant: 'dark',
            headerTextVariant: 'white',
            hideHeaderClose: false,
            centered: true
          })
          .then(value => {
            if (value) {
              var result = serviceAPI.updateProducto({
                id: item.id,
                product_id: item.product_id,
                customer_id: item.customer_id,
                discount: item.discount,
                additional: item.additional,
              })
                            
              result.then((response) => {                                                                                       
                this.$awn.warning("Actualizando acuerdo comercial");

                this.filterProducts(false, 'Acuerdo comercial actualizado')
              })
              .catch(error => {                
                this.filterProducts()                
                this.$awn.alert(Error.showError(error));
              })  
            }
          })    
          .catch(error => {
            this.$awn.alert(Error.showError(error));
          })  
        },
        forceRerender() {
          this.componentKey += 1;  
        },    
        
        // EXPORT
        openExport() {
          this.modal.formExport.active = true
          this.modal.formExport.title = "Exportación de Productos"
          
          if(this.customers) {
            if(this.customers.price_list)
              this.modal.formExport.price_list_id = this.customers.price_list.price_list_id
            else
              this.modal.formExport.price_list_id = this.getPublicPriceList.id          
          }
        },
        downloadSource(type) {
          var result = null
          var data = null

          switch (type) {
            case 'filter-full-list-price':
              this.modal.formExport.overlay = true

              this.filters.listFullToCSV = true
              this.filters.price_list_id = this.modal.formExport.price_list_id
              this.filters.last_update_days = 0
              this.filters.customerIDAgreement = this.customers.id     

              result = serviceAPI.filtrarProducto(this.filters)        
              result.then((response) => {          
                data = response.data
                Funciones.exportToCSV(data, this.customers.name + '-product-price-list-download')                    
                this.modal.formExport.overlay = false
              })
              .catch(error => {
                this.modal.formExport.overlay = false
              })
              break;
            case 'filter-list-price-last-update':
              this.modal.formExport.overlay = true

              this.filters.listFullToCSV = true
              this.filters.price_list_id = this.modal.formExport.price_list_id
              this.filters.last_update_days = 7
              this.filters.customerIDAgreement = this.customers.id     

              result = serviceAPI.filtrarProducto(this.filters)        
              result.then((response) => {          
                data = response.data
                Funciones.exportToCSV(data, this.customers.name + '-product-price-list-download')                    
                this.modal.formExport.overlay = false
              })
              .catch(error => {
                this.modal.formExport.overlay = false
              })
              break;            
          }        
        },      

        printPDF(type) {        
          switch (type) {
            case 'filter-full-list-price':
              this.filters.listFullToCSV = true
              this.filters.price_list_id = this.modal.formExport.price_list_id
              this.filters.last_update_days = 0
              this.filters.customerIDAgreement = this.customers.id     

              this.crud.print.key=Math.random()
              this.crud.print.filters=this.filters
              break;

            case 'filter-list-price-last-update':
              this.filters.listFullToCSV = true
              this.filters.price_list_id = this.modal.formExport.price_list_id
              this.filters.last_update_days = 7
              this.filters.customerIDAgreement = this.customers.id     
              
              this.crud.print.key=Math.random()
              this.crud.print.filters=this.filters
              break;            
          }
        },        
      }
    }
</script>
<style>
  .crud-products-customers-title {
    font-size: 10px;
    font-weight: 600;
  }
  .crud-products-customers-other {
    font-size: 10px;    
  }
  .crud-products-customers-code {
    color: gray;
  }
</style>